.agent {
  background-color: #fff;

  wg-login {
    .login-container {
      padding: 0 30px 30px;
      max-width: 640px;
      margin: auto;
    }

    .login-panel {
      display: flex;
    }

    .login-form {
      flex: 1;
      display: flex;
      flex-direction: column;

      .login-title h2 {
        color: #2d3249;
      }
    }

    .wg-logo > img {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .custom-form-group {
      width: 250px;
      margin-bottom: 40px;
      min-height: 0;
    }

    .custom-form-group input {
      width: 250px;
      border-radius: 0;
    }

    .btn.btn-primary {
      min-width: 120px;
      border-radius: 0;
      margin: 0;
      text-transform: uppercase;
      font-weight: normal;
      white-space: normal;
      height: auto;
      min-height: 34px;
      line-height: 1.5;
      padding: 7px 20px;
      word-wrap: break-word;
      max-width: 500px;

      &:not([disabled]):not(.disabled) {
        background-color: #f00;
      }
    }

    .form-actions {
      margin: 0;
    }

    wg-login-validate {
      display: block;
      flex: 1;

      form {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }

    wg-forgot-password,
    wg-forget-authenticator,
    wg-pending-token-activation,
    wg-back-page {
      display: none;
    }

    wg-login-otp form > div:first-of-type::before,
    wg-login-validate form > div:first-of-type::before {
      content: '';
      height: 142px;
      width: 142px;
      display: block;
      background-image: url(/assets/installers-theme/authpoint-icon@2x.png);
      background-repeat: no-repeat;
      background-size: contain;
      margin: 20px auto 35px;
    }

    wg-login-validate form div:not(.error-message) + div:not(.error-message) {
      display: none;
    }

    wg-forget-authenticator-form {
      max-width: 300px;

      .ui-dialog-buttonpane {
        margin-top: 10px;
      }

      .btn.btn-primary {
        max-width: 100%;
      }
    }

    .qrcode-image-container {
      height: 220px;
      width: 220px;
      margin: auto;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > wg-loader {
        margin: 0 !important;
      }
    }

    .qrcode-image {
      margin-bottom: 0;
    }

    .second-factory-options {
      display: block;
      color: #666666;
      width: 100%;

      ul {
        padding: 0;
        margin: 0;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, 1fr);

        li {
          list-style: none;

          a {
            transition: .4s ease-in-out;
            width: 100%;
            display: inline-block;
            padding: 10px;
            font-size: 11px;
            color: currentColor;
            text-align: center;
          }

          a.active-link {
            font-weight: bold;
          }

          img {
            display: block;
            height: 30px;
            margin: 0 auto 15px;
          }

          a:hover {
            background-color: #f1f1f1;
          }
        }
      }

      .second-factory-options-title {
        text-align: left;
        border-bottom: 2px solid #eee;
        padding-bottom: 5px;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }

    @media (max-width: 600px) {
      .second-factory-options {
        ul {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }

    @media (max-height: 900px) {
      .wg-logo > img {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}
