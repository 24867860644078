/* You can add global styles to this file, and also import other style files */
@import './themes/agent';
@import '@watchguard/wg-static/scss/wgc/theme/common/variables';

body {
  padding: 0;
}

$input-size: 290px;
.custom-form-group {
  margin: 0 auto;
  width: $input-size;
  input[type=text], input[type=password] {
    width: $input-size;
  }
  label {
    width: $input-size;
    text-align: left !important;
    padding-left: 5px;
  }
}

.form-actions {
  margin-top: 10px;
}

.login-subtitle {
  margin-bottom: 20px;
  & > h5 {
    color: #4D4D4D;
    font-size: 13px !important;
    & > label {
      color: #4D4D4D;
      font-size: 13px !important;
      font-weight: bold;
    }
  }
}

.error-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;

  > wg-icon {
    margin-right: 8px;

    svg, svg path, svg circle {
      fill: $color-ruby;
    }
  }

  > span {
    font-size: 13px;
    color: $color-ruby;
  }
}

wg-dialog .ui-dialog {
  width: 25%;
  text-align: center;
}

.p-dialog .p-dialog-content {
  margin: 30px 30px;
}

.p-dialog .p-dialog-header {
  padding-top: 18px
}

.p-dialog .p-dialog-header-icons button {
  top: 16px;
  margin-top: 0;
}

.p-dialog .p-dialog-header .p-dialog-title [dialog-title] {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.btn-back {
  position: absolute;
  padding: 5px;
  top: 0;
  left: 0;
}

.login-btn {
  margin-bottom: 20px;
  margin-top: 10px;
}

.text-back {
  color: #36BED6;
  font-size: 14px;
  cursor: pointer;
  margin: 20px;
}

.wg-logo > img {
  width: 180px;
}

input[type="password"]::-webkit-inner-spin-button,
input[type="text"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="password"]::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="password"]:-moz-placeholder,
input[type="text"]:-moz-placeholder {
  color: #cccccc;
}

.input-group input,
.input-group input:not([type=radio]):not([type=checkbox]),
.input-group .form-control {
  height: 31px;
}

.input-group:focus-within, input:focus, input.form-control:focus {
  border: 1px solid #b5b5b5;
  box-shadow: none;
  box-sizing: border-box;
}

wg-hide-show-password.invalid:focus-within,
.input-group.invalid:focus-within,
input.ng-invalid.ng-touched:focus-within {
  background-color: #fff0f0;
  border: 1px solid #cf0000;
  box-shadow: none;
  box-sizing: border-box;
}

show-errors div {
  text-align: left;
}
